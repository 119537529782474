import request from '@/utils/request'

export function uploadFile(url, data) {
    return request({
        method: 'post',
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: data
    })
}

export function postAction(url, parameter) {
    return request({
        url: url,
        method: 'post',
        data: parameter
    })
}

export function httpAction(url, parameter, method) {
    return request({
        url: url,
        method: method,
        data: parameter
    })
}

export function putAction(url, parameter) {
    return request({
        url: url,
        method: 'put',
        data: parameter
    })
}

export function getAction(url, parameter) {
    return request({
        url: url,
        method: 'get',
        params: parameter
    })
}

export function deleteAction(url, parameter) {
    return request({
        url: url,
        method: 'delete',
        params: parameter
    })
}

export function downFile(url, parameter) {
    return request({
        url: url,
        params: parameter,
        method: 'get',
        responseType: 'blob'
    })
}

export function downTemplate(url) {
    return request({
        url: url,
        // params: parameter,
        method: 'get',
        responseType: 'blob',
        headers: {
            'access-control-expose-headers': 'filename'
        }

    })
}
