import { getBaiduToken, setBaiduToken } from '@/utils/auth'
import { getAction } from '@/api/manage'
const audio = document.createElement('audio')
audio.addEventListener('ended', function() {
    rePlay(audio)
})
/**
 * 百度语音合成
 * @param bpmStatus
 * @returns {*}
 */
export function voicePrompt(text, spd) {
    text = replaceSpecialChar(text)
    /** const token = getBaiduToken()
    if (!token) {**/
    getAction('/baidu/getToken', {}).then((res) => {
        if (res.data) {
            setBaiduToken(res.data)
            audio.src = 'https://tsn.baidu.com/text2audio?lan=zh&ctp=1&cuid=16707494&' +
            'tok=' + res.data + '&' +
            'tex=' + encodeURIComponent(encodeURIComponent(text)) + '&vol=9&per=0&spd=' + spd + '&pit=5&aue=3'
            audio.play()
        }
    })
    /** } else {
        audio.src = 'https://tsn.baidu.com/text2audio?lan=zh&ctp=1&cuid=16707494&' +
      'tok=' + token + '&' +
      'tex=' + encodeURIComponent(encodeURIComponent(text)) + '&vol=9&per=0&spd=' + spd + '&pit=5&aue=3'
        audio.play()
    }**/
}

function rePlay(audio) {
    audio.play()
}

/**
 * 百度语音合成取消
 * @param bpmStatus
 * @returns {*}
 */
export function voiceCancel() {
    audio.pause()
}

/**
 * 整理冗余字符
 * @param bpmStatus
 * @returns {*}
 */
export function replaceSpecialChar(text) {
    text = text.replace('<br>', '')
    text = text.replace('</br>', '')
    text = text.replace('&nbsp;', '')
    text = text.replace('\n', '')
    text = text.replace('&nbsp;', '')
    return text
}
