<template>
    <div class="navbar">
        <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
        <span style="font-weight: bold;display: inline-block;font-size: 20px;line-height: 50px;margin-left: 8px;"></span>
        <breadcrumb class="breadcrumb-container" />
        <div class="right-menu">
            <switch-roles class="right-menu-item hover-effect" />
           <!-- <div class="avatar-container right-menu-item hover-effect" style="font-size: 2px;color:#000;margin-right:0px;cursor: default;">
                <i class="iconfont ali-icon-wulianwang-" style="font-size: 40px;cursor:pointer;" @click="goToDataView()"></i>
            </div>-->
           <!-- <div class="avatar-container right-menu-item hover-effect" style="font-size: 2px;color:#000;margin-right:0px;cursor: default;">
                <el-badge :value="messageCount" :max="99" class="item" style="line-height: 35px">
                    <el-button type="info" icon="el-icon-message" circle @click="listMyMessage"></el-button>
                </el-badge>
            </div>-->
            <template v-if="device!=='mobile'">
                <screenfull id="screenfull" class="right-menu-item hover-effect" />
                <size-select id="size-select" class="right-menu-item hover-effect" />
            </template>
            <div class="avatar-container right-menu-item hover-effect" style="font-size: 14px;color:#000;margin-right:0px;padding: 0 3px;cursor: default;">
                <div class="avatar-wrapper" style="display:table-cell;vertical-align:middle;text-align:center">
                    <span style="width:30px;height: 30px;">欢迎您</span>
                </div>
            </div>
            <div class="avatar-container right-menu-item hover-effect" style="font-size: 14px;color:#000;margin-right:0px;cursor: default;">
                <div class="avatar-wrapper" style="display:table-cell;vertical-align:middle;text-align:center">
                    <img v-if="avatar && avatar.indexOf('/upload/') != -1" :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar" style="margin-top:9px;">
                    <svg-icon v-else class-name="size-icon" icon-class="defaultHead" style="font-size: 30px;margin-top:9px;" />
                </div>
            </div>

            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click" style="font-size: 14px;color:#000;margin-right:0px;padding: 0 3px;">
                <div class="avatar-wrapper" style="display:table-cell;vertical-align:middle;text-align:center">
                    <span style="width:30px;height: 30px;">{{ username }}</span>
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <el-dropdown-item>
                        <span style="display:block;" @click="updatePasswordHandle">修改密码</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="avatar-container right-menu-item hover-effect" style="font-size: 14px;color:#000;margin-right: 18px;">
                <svg-icon class-name="size-icon" icon-class="exit" style="font-size: 16px;" />
                <span style="width:30px;height: 30px;" @click="logout">退出登录</span>
            </div>
        </div>
        <!-- 弹窗, 修改密码 -->
        <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
        <message-list v-if="messageVisible" ref="messageList" @ok="getMessageCount"></message-list>
        <!--<select-alarm ref="alarmForm" :visible="showAlarmRecord" :alarm-record="alarmList[0]" @close="alarmRecordClose" @dispose="toDispose" @choose="alarmRecordChoose"></select-alarm>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span>是否开启报警信息语音提示</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="onSpeak()">开启</el-button>
            </span>
        </el-dialog>-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAction } from '@/api/manage'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import SwitchRoles from '@/components/SwitchRoles'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import UpdatePassword from '@/components/UpdatePassword'
import MessageList from '@/components/MessageList'
import { getToken } from '../../utils/auth'
import { voicePrompt, voiceCancel } from '../../utils/baidu'
let wsTime = null
let wsSend = null
let ws = null
export default {
    components: {
        Breadcrumb,
        Hamburger,
        SwitchRoles,
        Screenfull,
        SizeSelect,
        UpdatePassword,
        MessageList
    },
    data() {
        return {
            messageList: [],
            alarmList: [],
            showAlarmRecord: false,
            isSpeak: false,
            dialogVisible: false,
            token: getToken(),
            showMsg: false,
            ws: null,
            voiceContent: null,
            messageVisible: false,
            messageCount: null,
            updatePassowrdVisible: false,
            username: this.$store.getters.name
        }
    },
    computed: {
        ...mapGetters([
            'sidebar',
            'avatar',
            'device'
        ])
    },
    created() {
       // this.getMessageCount()
        //this.websocket()
       // window.addEventListener('beforeunload', this.connClose)
    },
    beforeDestroy() {
        this.connClose()
    },
    destroyed() {
        window.removeEventListener('beforeunload', this.connClose)
    },
    mounted() {
    },
    methods: {
        removeAlarmRecord(alarmId) {
            // eslint-disable-next-line no-labels
            list:for (let i = 0; i < this.alarmList.length; i++) {
                if (this.alarmList[i].alarmId === alarmId) {
                    if (i === 0 && this.alarmList[0].alarmStatus === 2) {
                        this.messageList.splice(i, 1)
                        break list
                    }
                    if (i === 0) {
                        this.alarmRecordClose()
                    } else {
                        this.alarmList.splice(i, 1)
                        this.messageList.splice(i, 1)
                    }
                }
            }
            this.speakAlarmMessage()
        },
        toDispose() {
            this.alarmList[0].alarmStatus = 2
            const message = '{"type":2,"alarmId":"' + this.alarmList[0].alarmId + '"}'
            ws.send(message)
        },
        alarmRecordChoose() {
            // const message = '{"type":2,"alarmId":"' + this.alarmList[0].alarmId + '"}'
            // ws.send(message)
            this.alarmRecordClose()
        },
        alarmRecordClose() {
            this.showAlarmRecord = false
            this.messageList.splice(0, 1)
            this.alarmList.splice(0, 1)
            if (this.alarmList.length > 0) {
                this.showAlarmInfo()
            } else {
                voiceCancel()
            }
        },
        showAlarmInfo() {
            this.showAlarmRecord = true
            this.$refs.alarmForm.initMapPicture(this.alarmList[0])
        },
        onSpeak() {
            this.isSpeak = true
            this.dialogVisible = false
        },
        clickMessage() {
            voiceCancel()
            this.$router.push({ path: '/operate/alarmRecord?time=' + (new Date().getTime()) })
        },
        connClose() {
            ws.onclose = (event) => {
                if (wsTime) {
                    window.clearTimeout(wsTime)
                    wsTime = null
                }
            }
        },
        websocket() {
            // WebSocket start
            if ('WebSocket' in window) {
                ws = new WebSocket(`${process.env.VUE_APP_WS_URL}` + this.token)
                ws.onopen = (event) => {
                    console.log('open', event)
                }
                ws.onmessage = (event) => {
                    if (event.data == '连接成功') {
                        this.dialogVisible = true
                    }
                    if (event.data == 'token失效') {
                        this.connClose()
                        window.location.reload()
                    }
                    if (event.data != '连接成功' && event.data != '心跳' && event.data != 'token失效') {
                        let message = ''
                        const messageObj = JSON.parse(event.data)
                        console.log(messageObj)
                        if (messageObj.type === 2) {
                            this.removeAlarmRecord(messageObj.alarmId)
                            return
                        }
                        if (messageObj.type === 3) {
                            const callMessage = messageObj.bedId + '床头呼叫未接听'
                            const notify = this.$notify.warning({
                                title: '消息',
                                message: callMessage,
                                duration: 0,
                                onClick: () => {
                                    // this.clickMessage()
                                    notify.close()
                                    this.showMsg = false
                                }
                            })
                            this.showMsg = true
                        }
                        if (messageObj.type === 1 && messageObj.deviceType == 1) {
                            message = messageObj.customerName
                            if (messageObj.alarmAddress) {
                                message = message + '在' + messageObj.alarmAddress
                            }
                            message += '通过求救定位器报警'
                        } else {
                            message = messageObj.alarmAddress + '有一条新的报警'
                        }
                        if (messageObj.type === 1) {
                            this.messageList.push(message)
                            this.alarmList.push(messageObj)
                            this.showAlarmInfo()
                        }
                        // this.$notify.closeAll()
                        if (this.isSpeak) {
                            this.speakAlarmMessage()
                        }
                    }
                }
                ws.onerror = (event) => {
                    console.log('error', event)
                    if (wsTime) {
                        window.clearTimeout(wsTime)
                        wsTime = null
                    }
                    wsTime = window.setTimeout(() => {
                        this.websocket()
                    }, 3000)
                }
                ws.onclose = (event) => {
                    console.log('close', event)
                    if (wsTime) {
                        window.clearTimeout(wsTime)
                        wsTime = null
                    }
                    wsTime = window.setTimeout(() => {
                        this.websocket()
                    }, 3000)
                }
                // 维持心跳连接，处理60秒自动断开
                if (wsSend) {
                    window.clearInterval(wsSend)
                    wsSend = null
                }
                wsSend = window.setInterval(() => {
                    if (ws.readyState == 1) {
                        ws.send('心跳')
                    }
                }, 10000)
            } else {
                console.log('浏览器不支持 WebSocket..')
            }
            // WebSocket end
        },
        speakAlarmMessage() {
            console.log(this.messageList)
            let message = ''
            for (let a = this.messageList.length - 1; a >= 0; a--) {
                message += this.messageList[a] + ';'
            }
            // this.messageList.forEach(item => {
            //     message += item + ';'
            // })
            if (message) {
                voicePrompt(message, 5)
            } else {
                voiceCancel()
            }
        },
        getMessageCount() {
            getAction('/sys/sysMessage/getUnreadCount', null).then(res => {
                const { data } = res
                if (data > 0) {
                    this.messageCount = data
                } else {
                    this.messageCount = null
                }
            })
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        async logout() {
            await this.$store.dispatch('user/logout')
            this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        },
        updatePasswordHandle() {
            this.updatePassowrdVisible = true
            this.$nextTick(() => {
                this.$refs.updatePassowrd.init()
            })
        },
        listMyMessage() {
            this.messageVisible = true
            this.$nextTick(() => {
                this.$refs.messageList.list()
            })
        },
        goToDataView() {
            const new_href = this.$router.resolve('/dataView')
            window.open(new_href.href, '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
    .navbar {
        height: 50px;
        overflow: hidden;
        position: relative;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

        .hamburger-container {
            line-height: 46px;
            height: 100%;
            float: left;
            cursor: pointer;
            transition: background .3s;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                background: rgba(0, 0, 0, .025)
            }
        }

        .breadcrumb-container {
            float: left;
        }

        .right-menu {
            float: right;
            height: 100%;
            line-height: 50px;

            &:focus {
                outline: none;
            }

            .right-menu-item {
                display: inline-block;
                padding: 0 8px;
                height: 100%;
                font-size: 18px;
                color: #5a5e66;
                vertical-align: text-bottom;

                &.hover-effect {
                    cursor: pointer;
                    transition: background .3s;

                    &:hover {
                        background: rgba(0, 0, 0, .025)
                    }
                }
            }
            .avatar-container {

                .avatar-wrapper {
                    /*margin-top: 5px;*/
                    position: relative;

                    .user-avatar {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        border-radius: 16px;
                    }

                    .el-icon-caret-bottom {
                        cursor: pointer;
                        position: absolute;
                        right: -20px;
                        top: 25px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
</style>
