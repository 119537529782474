<template>
  <div class="dashboard-container">
    <p style="font-size: 100px;">欢迎使用
    </p>
  </div>

</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'Dashboard', // 此处应与菜单表 T_SYS_MENU.ROUTE_NAME 值相同
  components: {
    //MessageList
  },
  data() {
    return {
      messageVisible: false,
      writingReportdata: [],
      listMinelist: [],
      listMinetotal: '',
      loading: false,
      pageNo: 1,
      loading: false,
      newsList: [],
      pages: 1,
      // messageList: [],
      currentPage: 1
      /* tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
    }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
    }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
    }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
    }]*/
    }
  },
  created() {
    // this.getMessageCount()
  },
  mounted() {
    /* this.bedStatus()
     this.elderNursingLevel()
     this.todayServeRecord()
     this.elderAge()
     this.getwritingReport()
     this.getlistMine()
     this.occupancyStatistical()*/
  },
  methods: {
    /* listMyMessage() {
         this.messageVisible = true
         this.$nextTick(() => {
             this.$refs.messageList.list()
         })
     },*/
    /*  getMessageCount() {
          getAction('/sys/sysMessage/getUnreadCount', null).then(res => {
              const { data } = res
              if (data > 0) {
                  this.messageCount = data
              } else {
                  this.messageCount = null
              }
          })
      },*/
    // 获取顶部数据
    getwritingReport() {
      getAction('/home/writingReport').then((res) => {
        // console.log(res)
        this.writingReportdata = res.data
      })
    },
    // 待办事宜
    getlistMine() {
      const that = this
      const params = {
        pageNo: this.pageNo,
        pageSize: 5
      }
      getAction('/sys/sysMessage/listMine', params)
          .then(function(response) {
            console.log(response, 123)
            const pageInfo = response.data.records
            that.newsList = that.newsList.concat(pageInfo)
            // pageInfo.forEach(function (item) {

            // });
            console.log(that.newsList, 123)
            that.pages = pageInfo.data.pages
          })
          .catch(function(error) {
            console.log(error)
          })

      // getAction("/sys/sysMessage/listMine", params).then((res) => {
      //   // console.log(res)
      //   this.newsList = this.newsList.concat(res.data.records);
      //   this.loading = false;
      //   this.currentPage = res.data.pages;
      // });
    },
    bedStatus() {
      const myCharts1 = this.$echarts.init(this.$refs.myCharts1)
      getAction('/home/bedsStatistical').then((res) => {
        const { data } = res
        const options1 = {
          color: ['#ff7677', '#4386f4', '#fda43a'],
          title: {
            text: '床位统计', // 主标题文本
            // subtext: "床位统计", //副标题文本
            left: 'left'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          series: [
            {
              name: '数量',
              radius: ['40%', '55%'],
              type: 'pie',
              label: {
                normal: {
                  show: true,
                  formatter: '{b}{d}%',
                  textStyle: {
                    fontSize: 15
                  },
                  position: 'outside'
                },
                emphasis: {
                  show: true
                }
              },
              labelLine: {
                normal: {
                  show: true,
                  length: 5,
                  length2: 25
                },
                emphasis: {
                  show: true
                }
              },
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        myCharts1.setOption(options1)
      })
    },
    elderNursingLevel() {
      const myCharts2 = this.$echarts.init(this.$refs.myCharts2)
      getAction('/dataView/elderNursingLevel').then((res) => {
        //  console.log(res)
        const { data } = res
        const xDataArr = new Array()
        const serArr = new Array()
        for (let i = 0; i < data.length; i++) {
          xDataArr.push(data[i].text)
          serArr.push(data[i].num)
        }
        const options2 = {
          title: {
            text: '照护等级'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '6%',
            top: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xDataArr,
              axisTick: {
                alignWithLabel: true
              },
              splitLine: {
                // ---grid 区域中的分隔线
                show: false, // ---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                lineStyle: {
                  // color:'red',
                  // width:1,
                  // type:'solid',
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              minInterval: 1,
              splitLine: {
                // ---grid 区域中的分隔线
                show: false, // ---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                lineStyle: {
                  // color:'red',
                  // width:1,
                  // type:'solid',
                }
              }
            }
          ],
          series: [
            {
              data: serArr,
              type: 'bar',

              legendHoverLink: false,
              itemStyle: {
                // ---图形形状
                color: '#5764ff',
                barBorderRadius: [18, 18, 0, 0]
              },
              barWidth: '20',
              barCategoryGap: '20%' // ---柱形间距
            }
          ]
        }
        myCharts2.setOption(options2)
      })
    },
    todayServeRecord() {
      const myCharts3 = this.$echarts.init(this.$refs.myCharts3)
      getAction('/dataView/todayServeRecord').then((res) => {
        const { data } = res
        const options3 = {
          color: ['#ff868f', '#72a3ff', '#fda43a'],
          title: {
            text: '照护服务执行',
            // subtext: '纯属虚构',
            left: 'left'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '6%',
            top: '10%',
            containLabel: true
          },
          series: [
            {
              name: '数量',
              type: 'pie',
              radius: '50%',
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        myCharts3.setOption(options3)
      })
    },
    // 客户数量
    elderAge() {
      const myCharts4 = this.$echarts.init(this.$refs.myCharts4)
      const params = {
        type: 2
      }
      getAction('/home/customerNumberStatistical', params).then((res) => {
        const { data } = res

        const options4 = {
          title: {
            text: '客户数量'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '6%',
            top: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data.xAxis,
              axisTick: {
                alignWithLabel: true
              },
              splitLine: {
                // ---grid 区域中的分隔线
                show: false, // ---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                lineStyle: {
                  // color:'red',
                  // width:1,
                  // type:'solid',
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              minInterval: 1,
              splitLine: {
                // ---grid 区域中的分隔线
                show: false, // ---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                lineStyle: {
                  // color:'red',
                  // width:1,
                  // type:'solid',
                }
              }
            }
          ],
          series: [
            {
              data: data.series,
              type: 'line',
              symbolSize: 0,
              color: '#5764ff',
              smooth: true
            }
          ]
        }
        myCharts4.setOption(options4)
      })
    },
    // 入住统计
    occupancyStatistical() {
      const myCharts5 = this.$echarts.init(this.$refs.myCharts5)
      getAction('/home/occupancyStatistical').then((res) => {
        const { data } = res

        const options5 = {
          title: {
            text: '入住统计'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '6%',
            top: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data.xAxis,
              axisTick: {
                alignWithLabel: true
              },
              splitLine: {
                // ---grid 区域中的分隔线
                show: false, // ---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                lineStyle: {
                  // color:'red',
                  // width:1,
                  // type:'solid',
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              minInterval: 1,
              splitLine: {
                // ---grid 区域中的分隔线
                show: false, // ---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
                lineStyle: {
                  // color:'red',
                  // width:1,
                  // type:'solid',
                }
              }
            }
          ],
          series: [
            {
              data: data.occupancy,
              type: 'line',
              symbolSize: 0,
              color: '#3db8fe',
              smooth: true
            }
          ]
        }
        myCharts5.setOption(options5)
      })
    }
  }
}
</script>

<style  lang="scss">
.dashboard-container {
  padding: 10px;
  line-height: 2;
  text-align: center;
}

.el-row {
  margin-bottom: 10px;

  & :last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 300px;
  box-shadow: 1px 1px 6px 0px rgba(228, 228, 228, 0.5);
  border-radius: 5px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.title {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.boxs {
  width: 100%;
  height: 127px;
  padding: 0 15px;
  box-shadow: 1px 1px 6px 0px rgba(228, 228, 228, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    width: 57px;
    height: 57px;
    margin-top: 30px;
  }
  .left {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color: #ffffff;
    font-size: 24px;
    .top {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      .titles {
        color: #ffffff;
        font-size: 12px;
        padding: 0 15px;

        border-radius: 20px;
        letter-spacing: 1px;
      }
    }
  }
}
.one {
  background-image: linear-gradient(90deg, #ffd198 0%, #ff858f 100%);
  .titles {
    background-color: #ff868f;
  }
}
.two {
  background-image: linear-gradient(90deg, #ffd9a8 0%, #fda439 100%);
  .titles {
    background-color: #fda43a;
  }
}
.three {
  background-image: linear-gradient(90deg, #ffe789 0%, #ffce5b 100%);
  .titles {
    background-color: #ffc745;
  }
}
.four {
  background-image: linear-gradient(90deg, #b1cbff 1%, #6da0ff 100%);
  .titles {
    background-color: #6ea1ff;
  }
}
.conitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  background-color: #eef4ff;
  border-radius: 5px;
  // width: 100%;
  color: #5764ff;
  font-size: 12px;
  margin: 15px 20px;
  padding: 0 15px;
  .left {
    width: 40%;
  }
  .right {
    width: 55%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}
.contitle {
  color: #333333;
  font-weight: bold;
  font-size: 18px;
  padding:0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div{
    font-weight: normal;
    font-size: 14px;
    color: #5764ff;
    cursor: pointer;
  }
}
// .infinite-list-wrapper {
//   p {
//     text-align: center;
//   }
// }
.infinite-list-wrapper {
  width: 100%;
  // height: 240px;
  // border: 1px solid rebeccapurple;
}
.list {
  width: 100%;
}
.list li {
  height: 30px;
  margin: 5px 0;
  background: #8c939d;
  list-style: decimal;
}
.infinite-list-wrapper p {
  text-align: center;
}
.change {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>

