var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exception" }, [
    _c("div", { staticClass: "img" }, [
      _c("img", { attrs: { src: _vm.config[_vm.type].img } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("h1", [_vm._v(_vm._s(_vm.config[_vm.type].title))]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v(_vm._s(_vm.config[_vm.type].desc)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleToHome } },
            [_vm._v("返回首页")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }