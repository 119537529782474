var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("span", {
        staticStyle: {
          "font-weight": "bold",
          display: "inline-block",
          "font-size": "20px",
          "line-height": "50px",
          "margin-left": "8px",
        },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("switch-roles", { staticClass: "right-menu-item hover-effect" }),
          _vm._v(" "),
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _vm._v(" "),
                _c("size-select", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "size-select" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              staticStyle: {
                "font-size": "14px",
                color: "#000",
                "margin-right": "0px",
                cursor: "default",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "avatar-wrapper",
                  staticStyle: {
                    display: "table-cell",
                    "vertical-align": "middle",
                    "text-align": "center",
                  },
                },
                [
                  _vm.avatar && _vm.avatar.indexOf("/upload/") != -1
                    ? _c("img", {
                        staticClass: "user-avatar",
                        staticStyle: { "margin-top": "9px" },
                        attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" },
                      })
                    : _c("svg-icon", {
                        staticStyle: {
                          "font-size": "30px",
                          "margin-top": "9px",
                        },
                        attrs: {
                          "class-name": "size-icon",
                          "icon-class": "defaultHead",
                        },
                      }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              staticStyle: {
                "font-size": "14px",
                color: "#000",
                "margin-right": "0px",
                padding: "0 3px",
              },
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "avatar-wrapper",
                  staticStyle: {
                    display: "table-cell",
                    "vertical-align": "middle",
                    "text-align": "center",
                  },
                },
                [
                  _c(
                    "span",
                    { staticStyle: { width: "30px", height: "30px" } },
                    [_vm._v(_vm._s(_vm.username))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.updatePasswordHandle },
                      },
                      [_vm._v("修改密码")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              staticStyle: {
                "font-size": "14px",
                color: "#000",
                "margin-right": "18px",
              },
            },
            [
              _c("svg-icon", {
                staticStyle: { "font-size": "16px" },
                attrs: { "class-name": "size-icon", "icon-class": "exit" },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: { width: "30px", height: "30px" },
                  on: { click: _vm.logout },
                },
                [_vm._v("退出登录")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.updatePassowrdVisible
        ? _c("update-password", { ref: "updatePassowrd" })
        : _vm._e(),
      _vm._v(" "),
      _vm.messageVisible
        ? _c("message-list", {
            ref: "messageList",
            on: { ok: _vm.getMessageCount },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatar-container right-menu-item hover-effect",
        staticStyle: {
          "font-size": "14px",
          color: "#000",
          "margin-right": "0px",
          padding: "0 3px",
          cursor: "default",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "avatar-wrapper",
            staticStyle: {
              display: "table-cell",
              "vertical-align": "middle",
              "text-align": "center",
            },
          },
          [
            _c("span", { staticStyle: { width: "30px", height: "30px" } }, [
              _vm._v("欢迎您"),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }