import { isExternal } from '@/utils/validate'
import Layout from '@/layout'
import { getAction } from '@/api/manage'

export function getDicts(dictTypeCodes) {
    return getAction('/sys/codeInfo/getSysCodeInfos', { dictTypeCodes })
}

export function formatDictText(dicts, values) {
    if (!(Array.isArray(dicts) && dicts.length > 0)) {
        return values
    }
    if (values == null || values == undefined) {
        return ''
    }
    const valueArr = values.toString().split(',')
    const contentArr = []
    dicts.forEach(dict => {
        for (let i = 0; i < valueArr.length; i++) {
            if (valueArr[i] === dict.dictItemValue) {
                contentArr.push(dict.dictItemText)
                break
            }
        }
    })
    return contentArr.toString()
}
