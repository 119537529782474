import Vue from 'vue'

Vue.filter('NumberFormat', function(value) {
    if (!value) {
        return '0'
    }
    const intPartFormat = value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    return '¥ ' + intPartFormat
})

/** 字符串超长截取省略号显示 */
Vue.filter('ellipsis', function(value, vlength = 25) {
    if (!value) {
        return ''
    }
    if (value.length > vlength) {
        return value.slice(0, vlength) + '...'
    }
    return value
})

/* 阿拉伯数字转中文大写*/
Vue.filter('DX', function(value) {
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(value)) { return '数据非法' }
    var unit = '仟百拾亿仟百拾万仟百拾元角分'; var str = ''
    value += '00'
    var p = value.indexOf('.')
    if (p >= 0) { value = value.substring(0, p) + value.substr(p + 1, 2) }
    unit = unit.substr(unit.length - value.length)
    for (var i = 0; i < value.length; i++) { str += '零壹贰叁肆伍陆柒捌玖'.charAt(value.charAt(i)) + unit.charAt(i) }
    return str.replace(/零(仟|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整')
})
