var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.roles.length > 1
    ? _c(
        "section",
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              on: { change: _vm.changeRoles },
              model: {
                value: _vm.roleId,
                callback: function ($$v) {
                  _vm.roleId = $$v
                },
                expression: "roleId",
              },
            },
            _vm._l(_vm.roles, function (item) {
              return _c("el-option", {
                key: item.roleId,
                attrs: { label: item.roleName, value: item.roleId },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }