<template>
    <el-dialog
        title="我的消息"
        :visible.sync="visible"
        :append-to-body="true"
    >
        <el-collapse accordion>
            <el-collapse-item v-for="(item,index) in records" :title="item.title" :name="item.id" :value="item.id" class="item" @click.native="setStatus(item,index)">
                <template slot="title">
                    <el-tag v-if="item.messageType == 1" key="系统通知"type="warning" effect="dark" size="mini">系统通知</el-tag>
                    <el-tooltip class="item" effect="light" :content="item.title" placement="right" :disabled="item.title.length <= 30">
                        <span v-if="item.title.length <= 30" style="font-size: 15px;font-weight:bold;margin-left: 5px">{{ item.title }}</span>
                        <span v-if="item.title.length > 30" style="font-size: 15px;font-weight:bold;margin-left: 5px">{{ item.title.substring(0, 30) + "..." }}</span>
                    </el-tooltip>
                    <span style="margin-left: 5px">{{ item.sendTime }}</span>
                    <el-badge v-if="item.status === 1" value="new" class="item" />

                    <!--                                        <span style="font-size: 15px;font-weight:bold;margin-left: 5px">{{item.title}}</span>-->

                </template>
                <template> <span>{{ item.content }}</span></template>
            </el-collapse-item>
        </el-collapse>
        <pagination
            v-if="total > 10" v-show="total>0" :total="total" :page.sync="listQuery.pageNo" :limit.sync="listQuery.pageSize"
            style="padding-top:10px;text-align: right;" @pagination="list"
        />
        <span slot="footer" class="dialog-footer">
            <el-button icon="el-icon-close" @click="visible = false">关闭</el-button>
            <!--      <el-button icon="el-icon-check" type="primary" @click="dataFormSubmit()">确定</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
import { getAction, putAction } from '@/api/manage'
import Pagination from '@/components/Pagination'

export default {
    components: { Pagination },
    data() {
        return {
            activeName: 1,
            listQuery: {
                pageNo: 1,
                pageSize: 10
            },
            records: null,
            selectedRecords: [],
            total: 0,
            listLoading: false,
            page: {
                pageSize: undefined,
                currentPage: undefined
            },
            visible: false,
            dataForm: {
                password: '',
                newPassword: '',
                confirmPassword: ''
            }
        }
    },
    computed: {
    },
    methods: {
        setStatus(item, index) {
            if (item.status == 1) {
                putAction('/sys/sysMessage/read', { id: item.id, status: 2 }).then(res => {
                    this.$emit('ok')
                    item.status = 2
                })
            }
        },
        list() {
            this.visible = true
            this.listLoading = true
            getAction('/sys/sysMessage/listMine', this.listQuery).then(res => {
                const { data } = res
                this.page.pageSize = data.size
                this.page.currentPage = data.current
                this.records = data.records
                this.total = data.total
                this.listLoading = false
            })
        }
    }
}
</script>
