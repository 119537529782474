const types = {
    403: {
        img: '',
        title: '403',
        desc: '抱歉，你无权访问该页面'
    },
    404: {
        img: '',
        title: '404',
        desc: '抱歉，你访问的页面不存在或仍在开发中'
    },
    500: {
        img: '',
        title: '500',
        desc: '抱歉，服务器出错了'
    }
}

export default types
