var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "我的消息",
        visible: _vm.visible,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-collapse",
        { attrs: { accordion: "" } },
        _vm._l(_vm.records, function (item, index) {
          return _c(
            "el-collapse-item",
            {
              staticClass: "item",
              attrs: { title: item.title, name: item.id, value: item.id },
              nativeOn: {
                click: function ($event) {
                  return _vm.setStatus(item, index)
                },
              },
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  item.messageType == 1
                    ? _c(
                        "el-tag",
                        {
                          key: "系统通知",
                          attrs: {
                            type: "warning",
                            effect: "dark",
                            size: "mini",
                          },
                        },
                        [_vm._v("系统通知")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "light",
                        content: item.title,
                        placement: "right",
                        disabled: item.title.length <= 30,
                      },
                    },
                    [
                      item.title.length <= 30
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "15px",
                                "font-weight": "bold",
                                "margin-left": "5px",
                              },
                            },
                            [_vm._v(_vm._s(item.title))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.title.length > 30
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "15px",
                                "font-weight": "bold",
                                "margin-left": "5px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.title.substring(0, 30) + "...")
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "5px" } }, [
                    _vm._v(_vm._s(item.sendTime)),
                  ]),
                  _vm._v(" "),
                  item.status === 1
                    ? _c("el-badge", {
                        staticClass: "item",
                        attrs: { value: "new" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              [_c("span", [_vm._v(_vm._s(item.content))])],
            ],
            2
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.total > 10
        ? _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            staticStyle: { "padding-top": "10px", "text-align": "right" },
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.pageNo,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: _vm.list,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }