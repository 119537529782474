<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>
<style>
    /*表格错位解决*/
    body .el-table th.gutter{
        display: table-cell!important;
    }
</style>
