<template>
    <div id="index">

    </div>
</template>

<script>
import { formatTime1 } from '../../utils/index.js'

import { getAction } from '@/api/manage'

export default {
    components: {
    },
    data() {
        return {
            loading: true,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            rowStyle: {
                height: window.innerHeight * 0.9 + 'px'
            },
            winHeight: window.innerHeight,
            dateindex: 0,
            date: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            meanu: {},
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            options: {},
            options1: {},
            options2: {},
            options3: {},
            options4: {},
            scrollConfig: {},
            activeClass: '.swiper-contain' // 滑动模块名称
        }
    },
    mounted() {
        this.timeFn()
        this.cancelLoading()
        this.bedStatus()
        // this.getTodayMarket()
        this.getWeekMarket()
        this.currentAlarmRecord()
        // this.elderAge()
        this.elderNursingLevel()
        this.todayServeRecord()
        this.getPaymentMoneyByWeek()
        this.getMainUser()

        this.setNowTimes()
    },
    methods: {
        setNowTimes() {
            const myDate = new Date()
            // console.log(myDate)
            const wk = myDate.getDay()
            this.dateindex = wk - 1
            this.getWeekMenu()
        },
        // 切换
        getchange(index) {
            this.dateindex = index
            this.getWeekMenu()
        },
        timeFn() {
            setInterval(() => {
                this.dateDay = formatTime1(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime1(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        /** **************************获取首页需要展示的数据信息，根据数据渲染页面 **********************************************/
        // 数据获取
        // 床位状态
        bedStatus() {
            getAction('/dataView/bedStatus').then((res) => {
                const { data } = res
                const xDataArr = new Array()
                const serArr = new Array()
                for (let i = 0; i < data.length; i++) {
                    xDataArr.push(data[i].text)
                    serArr.push(data[i].num)
                }
                this.options1 = {
                    title: {
                        text: '床位状态',
                        textStyle: {
                            color: '#57c7ec'
                        },
                        padding: [1, 0, 0, 25] // 位置
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '6%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xDataArr,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            show: false // 是否显示
                        }
                    ],
                    series: [
                        {
                            data: serArr,
                            type: 'bar',
                            barWidth: 25, // 柱形的宽度
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: 'rgba(92, 217, 232, 1)' }, // 柱图渐变色
                                        { offset: 1, color: 'rgba(55, 140, 211, 0.1)' } // 柱图渐变色
                                    ])
                                }
                            }
                        }
                    ]
                }
            })
        },
        getTodayMarket() {
            getAction('/dataView/getTodayMarket').then((res) => {
                const { data } = res

                // console.log(res)
            })
        },
        // 周营销趋势统计
        getWeekMarket() {
            const leg = ['入住', '总入住']
            getAction('/dataView/getWeekMarket').then((res) => {
                const { data } = res
                const ser = new Array()
                const dateArr = new Array()
                for (let i = 0; i < data.length; i++) {
                    dateArr.push(
                        data[i].statisticsDate.substring(5, data[i].statisticsDate.length)
                    )
                }
                for (let i = 0; i < leg.length; i++) {
                    const dataItem = {}
                    dataItem.name = leg[i]
                    dataItem.type = 'line'
                    const dataItemData = new Array()
                    for (let j = 0; j < data.length; j++) {
                        if (leg[i] == '入住') {
                            dataItemData.push(data[j].moveIntoNum)
                        } else if (leg[i] == '总入住') {
                            dataItemData.push(data[j].sumMoveIntoNum)
                        }
                    }
                    dataItem.data = dataItemData
                    ser.push(dataItem)
                }
                // echarts渲染
                this.options = {
                    title: {
                        text: '入住统计',
                        textStyle: {
                            color: '#57c7ec'
                        },
                        padding: [0, 0, 0, 35] // 位置
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: leg,
                        orient: 'horizontal',
                        y: 'top',
                        x: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '6%',
                        bottom: '3%',
                        containLabel: true
                    },
                    /* toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },*/

                    xAxis: [
                        {
                            type: 'category',
                            data: dateArr,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    series: ser
                }
            })
        },
        // 报警列表
        currentAlarmRecord() {
            getAction('/dataView/currentAlarmRecord').then((res) => {
                const { data } = res
                this.scrollConfig.header = ['报警时间', '报警位置']
                this.scrollConfig.data = []
                for (let i = 0; i < data.records.length; i++) {
                    const rowArr = new Array()
                    rowArr.push(data.records[i].alarmTime)
                    rowArr.push(data.records[i].alarmAddress)
                    /* rowArr.push(data.records[i].disposeName)
                    rowArr.push(data.records[i].disposeTime)*/
                    this.scrollConfig.data.push(rowArr)
                }
                console.log(111, this.scrollConfig.data)
            })
        },
        // 护理等级分布
        elderNursingLevel() {
            getAction('/dataView/elderNursingLevel').then((res) => {
                //  console.log(res)
                const { data } = res
                const xDataArr = new Array()
                const serArr = new Array()
                for (let i = 0; i < data.length; i++) {
                    xDataArr.push(data[i].text)
                    serArr.push(data[i].num)
                }
                this.options3 = {
                    // ----  标题 -----
                    title: {
                        text: '护理等级',
                        textStyle: {
                            color: '#57c7ec'
                        },
                        padding: [1, 0, 0, 25] // 位置
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '6%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xDataArr,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            show: false // 是否显示
                        }
                    ],
                    series: [
                        {
                            data: serArr,
                            type: 'bar',
                            barWidth: 25, // 柱形的宽度
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: 'rgba(190, 160, 249, 1)' }, // 柱图渐变色
                                        { offset: 1, color: 'rgba(192, 160, 249, 0.1)' } // 柱图渐变色
                                    ])
                                }
                            }
                        }
                    ]
                }
            })
        },
        // 长者年年龄统计
        elderAge() {
            getAction('/dataView/elderAge').then((res) => {
                const { data } = res
                const xDataArr = new Array()
                const serArr = new Array()
                for (let i = 0; i < data.length; i++) {
                    xDataArr.push(data[i].name)
                    serArr.push(data[i].num)
                }
                this.options2 = {
                    title: {
                        text: '收入统计',
                        textStyle: {
                            color: '#57c7ec'
                        },
                        padding: [1, 0, 0, 25] // 位置
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '6%',
                        top: '8%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xDataArr,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            show: false // 是否显示
                        }
                    ],
                    series: [
                        {
                            data: serArr,
                            type: 'bar'
                        }
                    ]
                }
            })
        },
        // 今日照护数据
        todayServeRecord() {
            getAction('/dataView/todayServeRecord').then((res) => {
                const { data } = res
                this.options4 = {
                    color: ['#5086e9', '#69f4f1', '#bfa0f9'],
                    title: {
                        // text: this.total, //主标题文本
                        subtext: '执行情况', // 副标题文本
                        left: 'center',
                        top: '40%',
                        textStyle: {
                            fontSize: 35,
                            color: '#454c5c',
                            align: 'center'
                        },
                        subtextStyle: {
                            fontFamily: '微软雅黑',
                            fontSize: 20,
                            color: ' #ffffff'
                        }
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            name: '数量',
                            radius: ['50%', '70%'],
                            type: 'pie',
                            label: {
                                normal: {
                                    show: true,
                                    formatter: '{b}{d}%',
                                    textStyle: {
                                        fontSize: 15
                                    },
                                    position: 'outside'
                                },
                                emphasis: {
                                    show: true
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: true,
                                    length: 5,
                                    length2: 25
                                },
                                emphasis: {
                                    show: true
                                }
                            },
                            data: data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                }
            })
        },
        getPaymentMoneyByWeek() {
            getAction('/dataView/getPaymentMoneyByWeek').then((res) => {
                const { data } = res
                const xDataArr = new Array()
                const serArr = new Array()
                for (let i = data.length - 1; i >= 0; i--) {
                    xDataArr.push(data[i].time.substring(5, data[i].time.length))
                    serArr.push(data[i].money)
                }
                // echarts渲染
                this.options2 = {
                    title: {
                        text: '收入统计',
                        textStyle: {
                            color: '#57c7ec'
                        },
                        padding: [1, 0, 0, 25] // 位置
                    },
                    grid: {
                        left: '3%',
                        right: '6%',
                        bottom: '5%',
                        top: '20%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xDataArr,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            data: xDataArr,
                            axisLine: {
                                // 坐标轴 轴线
                                show: false // 是否显示
                            },
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    series: [
                        {
                            data: serArr,
                            symbolSize: 0,
                            type: 'line',
                            color: '#6bf5e3',
                            itemStyle: {
                                normal: {
                                    // 拐点上显示数值
                                    label: {
                                        show: false
                                    },
                                    lineStyle: {
                                        // 使用rgba设置折线透明度为0，可以视觉上隐藏折线
                                        color: '#6bf5e3'
                                    }
                                }
                            },
                            areaStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: 'rgba(113, 246, 195, 1)' }, // 柱图渐变色
                                        { offset: 1, color: 'rgba(113, 246, 195, 0.1)' } // 柱图渐变色
                                    ])
                                }
                            }
                        }
                    ]
                }
            })
        },
        // 医生今日风采
        getMainUser() {
            getAction('/dataView/getMainUser').then((res) => {
                const { data } = res
            })
        },
        // 菜单
        getWeekMenu() {
            const params = { date: this.dateindex }
            getAction('/dataView/getWeekMenu', params).then((res) => {
                const { data } = res
                this.meanu = data
            })
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/index.scss";

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.top1 {
  width: 100%;
  height: 43px;
  background: url(../../assets/top1.png) no-repeat;
  background-size: cover;
  background-position: center 0;
  color: #57c7ec;
  font-size: 22px;
  text-align: center;
  line-height: 50px;
}
.bottom1 {
  width: 100%;
  height: 6px;
  background: url(../../assets/bottom1.png) no-repeat;
  background-size: cover;
  background-position: center 0;
}
body {
  margin: 0;
  padding: 0;
}
.swiper-container {
  width: 500px;
  height: 300px;
  margin: 20px auto;
}
.border4 {
  background: url(../../assets/huli.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.caiputitle {
  position: absolute;
  top: 10px;
  left: 25px;
  color: #57c7ec;
  font-size: 18px;
  font-weight: bold;
}
.changedate {
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  width: 100%;

  justify-content: space-around;
  div {
    color: #ffffff;
    font-size: 14px;
    width: 68px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    cursor: pointer;
  }
  .hover {
    background: url(../../assets/dataview/hover.png) no-repeat;
    width: 68px;
    height: 100%;
  }
}
.boxcan {
  position: absolute;
  top: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.can {
  width:96%;
  padding: 0 25px;
  height: 32px;
  background-size: 100% 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .left {
    width: 10%;
    font-size: 16px;
    height: 100%;
    line-height: 33px;
    margin-right: 2%;
  }
  .right {
    flex: 1;
    overflow: hidden; //隐藏文字
    text-overflow: ellipsis; //显示 ...
    white-space: nowrap; //不换行
  }
}
.zao {
  background: url(../../assets/dataview/zao.png) no-repeat;
  background-size: 100% 100%;
}
.zhong {
  background: url(../../assets/dataview/zhong.png) no-repeat;
  background-size: 100% 100%;
}
.wan {
  background: url(../../assets/dataview/wan.png) no-repeat;
  background-size: 100% 100%;
}
</style>

