import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import Print from './components/vue-print-nb-jeecg'
import Storage from 'vue-ls'

const options = {
    namespace: 'raj__', // key键前缀
    name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
    storage: 'session' // 存储名称: session, local, memory
}

Vue.use(Storage, options)

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/config' // 配置文件&&静态变量文件
import '@/permission' // permission control
import { getDicts, formatDictText } from '@/utils/util'
import '@/utils/filter'
// 数据展示界面添加
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
// 适配flex
import '@/common/flexible.js'

// 引入全局css
import './assets/scss/style.scss'

import permission from './directive/permission/permission'

import './assets/icon/iconfont.css'

import echarts from 'echarts'

// 开发环境若需要走mock挡板，将注释去掉即可 development-开发 production-生产
// import {mockXHR} from '../mock'
if (process.env.NODE_ENV === 'development') {
    // mockXHR()
}

// Canvas绘图库
import fabric from 'fabric'
Vue.use(fabric)
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)
// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'glPvaFdlQRZEBGTgNTMEEZwyWXte1jsb'
})

const size = Cookies.get('size') || 'medium'
Vue.use(ElementUI, { locale, size })
Vue.use(Print)
Vue.prototype.$echarts = echarts // 这种方法是直接绑定在vue实例上，所以在项目中任何页面，直接 this.$echarts 即可
Vue.directive('permission', permission)

Vue.config.productionTip = false
Vue.prototype.getDicts = getDicts
Vue.prototype.formatDictText = formatDictText

import Moment from 'moment'

// 定义全局时间戳过滤器
Vue.filter('formatDate', function (value) {
    return Moment(value).format('YYYY-MM-DD')
})

// 添加签名
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
